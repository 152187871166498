import { FirebaseAuth } from '@perfweb/auth-lib'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import firebase from 'firebase/compat/app'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_SMARTBOARD_APP_NAME
}

const app = firebase.initializeApp(config)
const ui = new firebaseui.auth.AuthUI(firebase.auth())
export let globalProperties = {}

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      if (authResult.credential != null) {
        checkAuthorisationAndConnect()
        console.log('STEP 1')
      } else {
        if (window.location.href.toLowerCase().includes('/')) {
          console.log('STEP 2')
          checkAuthorisationAndConnect(true)
        } else {
          console.log('STEP 3')
          console.log('404-1')
          firebase.auth().signOut()
          window.location.href = '/page-404.html'
        }
      }
    },
    uiShown: () => {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('loader').style.display = 'none'
      document.getElementById('firebaseui-auth-container').style.padding = '40px 24px'
      document.body.style.backgroundColor = '#f8f8f8'
      document.getElementById('loader').style.marginLeft = 'auto'
      document.getElementById('loader').style.marginRight = 'auto'
      document.getElementById('loader').style.marginTop = '80px'

      // ajoute le nouvel élément créé et son contenu dans le DOM
      var currentDiv = document.getElementById('firebaseui-auth-container')
      currentDiv.getElementsByTagName('form')

      let form1 = currentDiv.getElementsByTagName('form')[0]

      const img = document.createElement('img')
      img.setAttribute('id', 'BI-AUCHAN')
      img.src = './img/BI_AUCHAN.png'
      img.style.border = '3px solid #dd2c00'
      img.style.borderRadius = '10px'
      img.style.display = 'block'
      img.style.marginLeft = 'auto'
      img.style.marginRight = 'auto'
      img.style.marginTop = '124px'
      // document.body.appendChild(img);
      // document.body.insertBefore(img, currentDiv)

      var newDiv = document.createElement('div')
      newDiv.style.textAlign = 'center'
      newDiv.style.marginTop = '-155px'
      newDiv.appendChild(document.getElementById('loader'))
      document.body.appendChild(newDiv)
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  //signInSuccessUrl: 'https://localhost:8080/',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      fullLabel: 'Fournisseur - Se connecter avec son adresse mail',
      requireDisplayName: false
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      providerName: 'Auchan Account',
      loginHintKey: 'login_hint',
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account'
      },
      fullLabel: 'Utilisateur - Se connecter avec son compte Auchan',
      iconUrl: 'https://www.auchan.fr/content-renderer/sav_2022.3.11-4/images/auchan-logo-mobile.svg'
    }
  ]
}

const checkAuthorisationAndConnect = async (isRVF = false) => {
  let fbAuth = new FirebaseAuth(config, app, ['development', 'staging'].includes(process.env.VUE_APP_BUILD_ENV)).signIn().catch(signInError => {
    console.warn('Error on authentification', signInError)
  })

  if (isRVF) {
    fbAuth.then(({ firebaseUser, user, roles }) => {
      user = user ?? firebaseUser
      roles = roles ?? []
      globalProperties = { firebaseUser, user, roles }
      if (user) {
        import('./store/modules/v2/supplier.store').then(app => {
          let promise = app.authorizationExternal(firebaseUser)
          promise.then(result => {
            if (result.length === 0) {
              firebase.auth().signOut()
              window.location.href = '/page-404.html'
            } else {
              import('./createApp').then(createApp => {
                createApp.createApp({ token: firebaseUser.accessToken, user, roles })
              })
            }
          })
        })
      }

    })
  } else {
    fbAuth
      .then(a => a.authorization())
      .then(({ firebaseUser, user, roles }) => {
        globalProperties = { firebaseUser, user, roles }
        import('./createApp').then(createApp => {
          createApp.createApp({ token: firebaseUser.accessToken, user, roles })
        })
      })
  }
}

firebase.auth().onAuthStateChanged(connectedUser => {
  if (connectedUser) {
    if (connectedUser.displayName) {
      checkAuthorisationAndConnect()
      console.log('STEP 4')
    } else {
      if (window.location.href.toLowerCase().includes('/')) {
        checkAuthorisationAndConnect(true)
        console.log('STEP 5')
      } else {
        firebase.auth().signOut()
        window.location.href = '/page-404.html?fournisseur'
        console.log('STEP 6')
      }
    }
  } else {
    //Delete second connexion option (log with auchan account)
    if (window.location.href.toLowerCase().includes('/rvf')) {
      uiConfig.signInOptions.splice(1, 1)
      console.log('STEP 7')
    }
    console.log('STEP 8')
    ui.start('#firebaseui-auth-container', uiConfig)
  }
})

export const logout = () => {
  firebase.auth().signOut()
  window.location.href = '/'
}
